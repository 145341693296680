import {StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';

export class StyleParams {
  constructor(private readonly sdk: IEditorSdk, private readonly applicationId: number) {}

  public async setCheckoutStyleParam(type: string, key: string, param: {value: any}) {
    const allComponents = await this.sdk.document.tpa.app.getAllCompsByApplicationId('', this.applicationId);
    const checkoutComponent = allComponents.find((comp) => comp.widgetId === StoresWidgetID.CHECKOUT);
    if (!checkoutComponent) {
      return;
    }
    const compRef = await this.sdk.document.components.getById('', {id: checkoutComponent.id});

    await this.sdk.tpa.setStyleParams('', {
      compRef,
      styleParams: [{type, key, param}],
    });
  }
}
