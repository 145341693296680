export async function doTransaction(sdk: IEditorSdk, fn: Function) {
  try {
    return await sdk.document.transactions.runAndWaitForApproval('', fn);
  } catch (e) {
    const isTransactionError = await sdk.document.transactions.isConflictError('', e);
    if (isTransactionError) {
      return await sdk.document.transactions.runAndWaitForApproval('', fn);
    }
  }
}
