import {ecomAppDefID} from '../constants';

async function getRef(sdk: IEditorSdk) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const ecomComps = await (sdk.document.tpa as any).app.getAllCompsByApplicationId('', tpaApplicationId);
  return ecomComps?.[0].id;
}

export async function freeProductsLegacyFlag(sdk: IEditorSdk, allowFreeProducts: boolean = false) {
  const compId = await getRef(sdk);

  if (!compId) {
    throw new Error('cannot find a compId for freeProductsLegacyFlag');
  }

  await (sdk.document.tpa as any).data.set('', {
    compRef: {id: compId, type: 'DESKTOP'},
    key: 'ALLOW_FREE_PRODUCTS',
    value: allowFreeProducts,
    scope: 'APP',
  });
}
